<template>
  <div>
    <div
      v-if="answer.length === 1"
    >
      <span class="text-large text-black text-uppercase ghd-ITCAvant">
        {{ currentAnswer[0].answer }}
      </span>

      <p v-html="currentAnswer[0].answer_description">
      </p>

      <div v-if="currentAnswer[0].followup_question">
        <v-row>
          <v-col
            v-for="(follow, index) in currentAnswer[0].followup_question"
            :key="index"
            cols="6"
          >
            <v-btn
              tile
              color="primary"
              class="w-100 btn-block text-medium responseArticole"
              @click="setCurrentAnswer(follow)"
            >
              {{ follow.answer }}
            </v-btn>
          </v-col>
        </v-row>
      </div>

      <div v-else>
        <v-row class="mt-5">
          <v-col
            cols="12"
            style="padding: 0 12px"
          >
            <p class="ghd-ITCAvant text-black text-medium p-0 text-uppercase">
              Siamo riusciti a risolvere il tuo problema?
            </p>
          </v-col>
          <v-col
            cols="6"
          >
            <v-btn
              tile
              color="primary"
              class="w-100 btn-block text-medium responseArticole"
              @click="openDialog('YES')"
            >
              Si
            </v-btn>
          </v-col>

          <v-col
            cols="6"
          >
            <v-btn
              tile
              color="primary"
              class="w-100 btn-block text-medium responseArticole"
              @click="openDialog('NO')"
            >
              No
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
    <dialog-resolved-problem
      v-if="isDialogResolvedProblemVisible"
      :data="dialogData"
      @turnoff="closeDialog()"
    >
      <template v-slot:action>
        <router-link
          v-if="dialogData.buttonAction === 'redirect'"
          :to="{name: 'dashboard'}"
        >
          <v-btn
            color="primary"
            style="border: 2px solid white !important;"
          >
            {{ dialogData.buttonText }}
          </v-btn>
        </router-link>

        <v-btn
          v-else
          color="primary"
          style="border: 2px solid white !important;"
          @click="startAssistance(true)"
        >
          {{ dialogData.buttonText }}
        </v-btn>
      </template>
    </dialog-resolved-problem>
  </div>
</template>

<script>

import { ref, watchEffect } from '@vue/composition-api'
import store from '@/store'
import assistanceService from '@/services/assistanceService'
import DialogResolvedProblem from '@/views/dialogs/dialogResolvedProblem.vue'

export default {
  name: 'AnswerQuestion',
  components: { DialogResolvedProblem },
  props: {
    answer: {
      type: [Array, Object],
      required: true,
    },
  },
  setup(props) {
    const isDialogResolvedProblemVisible = ref(false)
    const dialogData = ref({})

    const {
      getHeaderText,
    } = assistanceService()

    const currentAnswer = ref({})

    watchEffect(() => {
      currentAnswer.value = props.answer
    })

    const setupFollowupQuestion = [
      {
        class: 'primary',
      },
      {
        class: 'accent',
      },
    ]

    const setCurrentAnswer = answer => {
      currentAnswer.value = [answer]
    }

    const setActiveStep = val => {
      store.commit('assistance/setActiveStep', val)
    }

    const dialogDataToLoad = {
      YES: {
        title: 'PROBLEMA RISOLTO',
        text: 'Ti ringraziamo per aver usato l\'assistenza online ghd',
        buttonText: 'TORNA ALLA HOME',
        buttonAction: 'redirect',
      },
      NO: {
        title: 'PROBLEMA NON RISOLTO',
        text: 'Per ulteriore assistenza apri una pratica di assistenza.',
        buttonText: 'APRI ASSISTENZA',
        buttonAction: 'start',
      },
    }

    const openDialog = key => {
      isDialogResolvedProblemVisible.value = true
      dialogData.value = dialogDataToLoad[key]
    }

    const closeDialog = () => {
      isDialogResolvedProblemVisible.value = false
      dialogData.value = {}
    }

    const startAssistance = val => {
      closeDialog()
      store.commit('assistance/setStartAssistance', val)
      if (val) {
        store.commit('assistance/setHeaderTextMessages', getHeaderText().replace('ASSISTENZA PER ', ''))
        store.commit('assistance/setSubHeaderTextMessages', null)
        store.commit('global/setBreadCrumbs', ['Home', getHeaderText().replace('ASSISTENZA PER ', ''), 'Pratica assistenza'])
      }
      setActiveStep(4)
    }

    return {
      currentAnswer,
      setupFollowupQuestion,
      isDialogResolvedProblemVisible,

      dialogData,
      openDialog,
      closeDialog,
      setActiveStep,
      startAssistance,
      setCurrentAnswer,
    }
  },
}
</script>

<style scoped>
.responseArticole:hover{
background-color: rgb(160, 131, 90) !important;;
}
</style>
