<template>
  <v-dialog
    v-model="isDialogVisible"
    persistent
    width="900"
  >
    <v-card>
      <div class="d-flex pa-6">
        <div>
          <img
            src="/images/logo-nero.png"
            width="70"
          >
        </div>
        <div
          class="ml-auto cursor-pointer"
          @click="$emit('turnoff')"
        >
          <svg
            version="1.1"
            x="0px"
            y="0px"
            width="20"
            height="20"
            viewBox="0 0 219.57 220.6"
            style="enable-background:new 0 0 219.57 220.6;"
            xml:space="preserve"
          >
            <path
              fill="black"
              d="M213.72,9.06L213.72,9.06c3.12,3.12,3.12,8.19,0,11.31L17.85,216.24c-3.12,3.12-8.19,3.12-11.31,0l0,0c-3.12-3.12-3.12-8.19,0-11.31L202.4,9.06C205.53,5.93,210.59,5.93,213.72,9.06z"
            />
            <path
              fill="black"
              d="M213.72,215.79L213.72,215.79c-3.12,3.12-8.19,3.12-11.31,0L6.54,19.92c-3.12-3.12-3.12-8.19,0-11.31l0,0c3.12-3.12,8.19-3.12,11.31,0l195.87,195.87C216.84,207.6,216.84,212.67,213.72,215.79z"
            />
          </svg>
        </div>
      </div>

      <div class="ghd-text-black ghd-ITCAvant text-uppercase text-black ml-6 text-large">
        Dove si trova il codice data?
      </div>
      <v-card-subtitle class="ghd-helveticanew text-black">
        Il codice data può essere trovato sull'etichetta con le informazioni sul prodotto, nella parte interna dello styler, vicino alle parole Date Code:
      </v-card-subtitle>
      <v-card-text>
        <v-img
          src="@/assets/images/WhereIsDateCode.png"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'

export default {
  setup() {
    const isDialogVisible = ref(true)

    return { isDialogVisible }
  },
}
</script>

<style scoped>

</style>
