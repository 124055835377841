<template>
  <div v-if="products.value">
    <div class="text-center mb-9">
      <span
        v-if="getSubHeaderText()"
        class="subheader"
      >
        {{ getSubHeaderText() }}
      </span>
    </div>
    <v-row>
      <v-col
        cols="12"
        :md="activeStep > 4 ? '4' : '10'"
        sm="12"
        order="1"
        class="align-self-start"
        :class="activeStep > 4 ? 'offset-md-4' : 'offset-md-1'"
      >
        <v-alert
          v-if="checkIfNoGarMessageCanPrinted()"
          border="top"
          colored-border
          text
          color="error"
          class="mb-4 ghd-ITCAvant"
          style="color: black !important;"
        >
          {{ vErrorMessage.toUpperCase() }}
        </v-alert>
        <v-alert
          v-if="checkIfGarMessageCanPrinted()"
          border="top"
          colored-border
          text
          color="primary"
          class="mb-4 ghd-ITCAvant text-black text-uppercase"
        >
          Il tuo prodotto risulta essere nei tempi di garanzia.
        </v-alert>
        <v-stepper
          v-model="activeStep"
          class="custom-header"
          :class="activeStep > 2 ? 'ghd-border-black' : ''"
        >
          <!-- Stepper Items -->
          <v-stepper-items>
            <!-- Stepper Content: Step 1 -->
            <v-stepper-content step="1">
              <v-row>
                <v-col
                  v-for="category in products.value"
                  :key="category.id"
                  md="3"
                  sm="6"
                  cols="12"
                >
                  <v-card
                    class="ghd-border-black"
                    @click="productInSelection.category = category; handleStepValidation(1, true, false)"
                  >
                    <v-img
                      :src="category.url"
                    ></v-img>
                    <v-card-subtitle class="text-black ghd-ITCAvant text-center text-medium text-uppercase">
                      {{ category.label }}
                    </v-card-subtitle>
                  </v-card>
                </v-col>

                <v-col
                  md="3"
                  sm="6"
                  cols="12"
                >
                  <v-card
                    class="ghd-border-black"
                    @click="openWhatsAppDialog()"
                  >
                    <v-img
                      src="@/assets/images/svg/accessori.svg"
                    />
                    <v-card-subtitle class="text-black ghd-ITCAvant text-center text-medium text-uppercase">
                      Accessori
                    </v-card-subtitle>
                  </v-card>
                </v-col>
              </v-row>
            </v-stepper-content>

            <!-- Stepper Content: Step 2 -->
            <v-stepper-content step="2">
              <v-row>
                <v-col
                  v-for="product in handleListOfItems()"
                  :key="product.id"
                  md="3"
                  sm="6"
                  cols="12"
                >
                  <v-card
                    class="ghd-border-black"
                    style="height: 100%; margin: 0.5rem 1rem"
                    @click="assignCurrentProduct(product); handleStepValidation(2, true, false)"
                  >
                    <v-img
                      :src="product.url"
                    ></v-img>
                    <v-card-subtitle class="text-black ghd-ITCAvant text-medium">
                      {{ product.label }}
                    </v-card-subtitle>
                  </v-card>
                </v-col>
              </v-row>

              <div class="d-flex justify-space-between mt-16">
                <v-btn
                  outlined
                  @click="handleStepValidation(1, false)"
                >
                  Annulla
                </v-btn>
              </div>
            </v-stepper-content>

            <!-- Stepper Content: Step 3 -->
            <v-stepper-content step="3">
              <ProductFaqs
                :product="productInSelection.product"
                :faqs.sync="productInSelection.product.faqs"
              />
              <div class="d-flex justify-space-between mt-16">
                <v-btn
                  outlined
                  @click="handleStepValidation(2, false)"
                >
                  Indietro
                </v-btn>
              </div>
            </v-stepper-content>

            <!-- Stepper Content: Step 4 -->
            <v-stepper-content step="4">
              <v-form
                ref="step4Form"
                class="pb-6 pt-2 multi-col-validation"
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    class="pb-1"
                  >
                    <div class="text-uppercase ghd-ITCAvant text-black">
                      Data di acquisto
                    </div>
                    <v-menu
                      ref="menuref"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="productInSelection.product.purchaseDate"
                          label="Data di acquisto"
                          :disabled="productInSelection.product.isOwnCodDocument || productInSelection.product.noProofOfPurchase"
                          outlined
                          readonly
                          v-bind="attrs"
                          :rules="[validators.requiredIf(productInSelection.product.purchaseDate, productInSelection.product.noProofOfPurchase)]"
                          v-on="on"
                        ></v-text-field>
                      </template>

                      <v-date-picker
                        ref="picker"
                        v-model="productInSelection.product.purchaseDate"
                        :max="new Date().toISOString().substr(0, 10)"
                        min="1900-01-01"
                        color="primary"
                        locale="it-IT"
                        :first-day-of-week="1"
                        @change="save"
                      ></v-date-picker>
                    </v-menu>
                    <v-checkbox
                      v-if="!productInSelection.product.isOwnCodDocument && !checkIfUserIsSalone()"
                      v-model="productInSelection.product.noProofOfPurchase"
                      hide-details
                      label="Non possiedo la prova d'acquisto"
                      class="ghd-helveticanew mt-0 pt-0"
                      @change="setNoProofOfPurchase()"
                    ></v-checkbox>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                    class="pb-1"
                  >
                    <div
                      v-if="!checkIfUserIsSalone()"
                    >
                      <div class="text-uppercase ghd-ITCAvant text-black">
                        Luogo di acquisto
                      </div>
                      <v-select
                        v-model="productInSelection.product.purchaseLocation"
                        :items="getLuoghiAcquisto()"
                        placeholder="Luogo di acquisto"
                        label="Luogo di acquisto"
                        outlined

                        hide-details="auto"
                        :rules="[validators.required]"
                      />
                    </div>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                  >
                    <div class="text-uppercase ghd-ITCAvant text-black">
                      Numero Seriale (Date Code)
                    </div>
                    <v-text-field
                      v-model="productInSelection.product.serialNumber"
                      :disabled="productInSelection.product.serialNumberReadable || productInSelection.product.hasOwnProperty('isOwnCodDocument')"
                      label="Numero Seriale (Date Code)"
                      outlined
                      hide-details="auto"
                      :append-icon="icons.mdiHelpCircle"
                      :rules="[ validators.required,
                                validators.checkIfDataCodeIsFake(productInSelection.product.serialNumber),
                                validators.rulesSyntaxSerialNumberValidator(productInSelection.product.serialNumber),
                                validators.dateOfPurchaseWarrantyValidity(productInSelection.product.serialNumber, productInSelection.product.purchaseDate)
                      ]"
                      placeholder="Numero Seriale (Date Code)"
                      @click:append="isDialogWhereisDataCodeVisible = true"
                    ></v-text-field>

                    <v-checkbox
                      v-if="!productInSelection.product.autocompiled && !checkIfUserIsSalone()"
                      v-model="productInSelection.product.serialNumberReadable"
                      hide-details
                      label="Non leggibile"
                      class="ghd-helveticanew mt-0"
                      @click="setSerialNumberReadable"
                    ></v-checkbox>
                  </v-col>

                  <v-col
                    v-if="!productInSelection.product.isOwnCodDocument"
                    cols="12"
                    md="6"
                  ></v-col>

                  <v-col
                    v-if="!productInSelection.product.isOwnCodDocument"
                    cols="12"
                    md="6"
                  >
                    <div class="text-uppercase ghd-ITCAvant text-black">
                      {{ labelForProofOfPurchase() }} <small>(Max 8MB)</small>
                    </div>
                    <small>Formati accettati: Pdf, Jpg, Jpeg, Png</small>
                    <v-file-input
                      v-model="productInSelection.product.proofOfPurchase"
                      :disabled="productInSelection.product.noProofOfPurchase"
                      :label="placeholderForProofOfPurchase()"
                      outlined
                      show-size
                      :rules="handleRules('proofOfPurchase')"
                    ></v-file-input>
                  </v-col>
                  <v-col
                    v-if="!productInSelection.product.isOwnCodDocument && checkIfUserIsSalone()"
                    cols="12"
                    md="6"
                  >
                    <div class="text-uppercase ghd-ITCAvant text-black">
                      Numero documento (FDI)
                    </div>

                    <v-text-field
                      v-if="!productInSelection.product.isOwnCodDocument && checkIfUserIsSalone()"
                      v-model="productInSelection.product.nr_fattura"
                      :disabled="productInSelection.product.noProofOfPurchase"
                      label="Numero documento (FDI)"
                      outlined
                      show-size
                      :append-icon="icons.mdiHelpCircle"
                      :rules="[
                        validators.required
                        ]"
                      @click:append="isDialogWhereIsNFDI = true"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    v-if="!checkIfUserIsSalone()"
                    cols="12"
                    md="12"
                    class="pb-1"
                  >
                    <div class="ghd-ITCAvant text-uppercase text-black">
                      Foto Prodotto (almeno 4 foto) <small>(Max 8MB)</small>
                    </div>
                    <small>Formati accettati: Jpg, Jpeg, Png</small>
                  </v-col>

                  <v-col
                    v-for="i in ['F','B','R','L']"
                    v-if="!checkIfUserIsSalone()"
                    :key="i"
                    cols="12"
                    md="6"
                    class="pt-1"
                  >
                    <v-file-input
                      :label="handleFileUploadTexts(i)"
                      outlined
                      show-size
                      :rules="[
                        validators.fileMaxSize,
                        validators.fileExtensionAccepted(productInSelection.product.itemPhotos[i],['image/jpg', 'image/jpeg', 'image/png'])
                      ]"
                      @change="file => setFileOnBase64ItemPhoto(file, i)"
                    ></v-file-input>
                  </v-col>

                  <v-col
                    cols="12"
                    md="12"
                    class="pb-1"
                  >
                    <div class="text-uppercase ghd-ITCAvant text-black">
                      Difetto riscontrato
                    </div>

                    <v-select
                      v-model="productInSelection.product.damage"
                      :items="productInSelection.product.defects"
                      item-text="descrizione"
                      item-value="code"
                      label="Difetto riscontrato"
                      outlined
                      :rules="[validators.required]"
                      @change="changeGarIfDamageIsFalldown"
                    ></v-select>
                  </v-col>

                  <v-col
                    v-if="productInSelection.product.damage === 'D023'"
                    cols="12"
                    md="12"
                  >
                    <v-textarea
                      v-model="productInSelection.product.descDamage"
                      label="Descrivi il difetto o guasto riscontrato"
                      rows="6"
                      outlined
                      :rules="[validators.required]"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
              <div
                v-if="startAssistance"
                class="d-flex justify-space-between"
              >
                <div>
                  <dialog-info-garanzia />
                </div>
                <div
                  v-if="canSendProduct"
                  class="ml-auto"
                >
                  <v-btn
                    color="primary"
                    class="me-2"
                    @click="handleStepValidation(4, true, false)"
                  >
                    Aggiungi Prodotto
                  </v-btn>
                </div>
              </div>
            </v-stepper-content>

            <!-- Stepper Content: Step 5 -->
            <v-stepper-content step="5">
              <div
                v-if="sendData.prodotti.length"
              >
                <div class="mb-2 text-medium text-uppercase text-black text-bolder ghd-ITCAvant">
                  La tua pratica di assistenza
                </div>
                <v-card
                  v-for="(item, index) in sendData.prodotti"
                  :key="index"
                  class="ghd-border-black mb-4"
                >
                  <div
                    class="d-flex justify-space-between flex-wrap flex-md-nowrap flex-column flex-md-row"
                  >
                    <div class="align-self-start">
                      <v-img
                        width="100"
                        height="100%"
                        :src="item.url"
                        class="rounded"
                      ></v-img>
                    </div>

                    <div class="pt-2">
                      <span class="text-black ghd-ITCAvant text-center text-medium">{{
                        item.prodotto
                      }}</span>
                      <v-card-text class="pl-0">
                        Seriale: {{ item.seriale }}
                      </v-card-text>
                      <v-card-text class="text--primary text-base">
                      </v-card-text>
                      <v-card-actions class="d-flex justify-space-between dense">
                      </v-card-actions>
                    </div>

                    <div class="align-self-center mr-auto">
                      <v-btn
                        icon
                        color="primary"
                        class="align-self-center"
                        @click="removeItem(index)"
                      >
                        <v-icon>
                          {{ icons.mdiClose }}
                        </v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-card>
                <h4 class="text-sm">
                  <div>
                    * In caso di esaurimento scorte, le edizioni limitate saranno sostituite con le colorazioni disponibili della collezione continuativa.
                  </div>
                  <div
                    v-if="!checkIfAssistanceNeedConfirmation()"
                    class="mt-4"
                  >
                    * LA SOSTITUZIONE IN GARANZIA VERRÀ CONFERMATA A SEGUITO DEL CONTROLLO DEL PRODOTTO.
                  </div>
                </h4>
                <v-btn
                  color="primary"
                  block
                  class="mb-4 mt-4"
                  @click="handleClosingStep"
                >
                  Concludi
                </v-btn>
                <div v-if="false">
                  <h4 class="text-sm">
                    Vuoi aggiungere un ulteriore prodotto alla tua pratica di
                    assistenza?
                  </h4>
                  <v-btn
                    class="ghd-border-black mt-4"
                    color="primary"
                    outlined
                    block
                    @click="handleStepValidation(5, true, true)"
                  >
                    Aggiungi Prodotto
                  </v-btn>
                </div>
              </div>
            </v-stepper-content>

            <!-- Stepper Content: Step 6 -->
            <v-stepper-content step="6">
              <div
                v-if="sendData.prodotti.length"
              >
                <span class="mb-5 text-medium text-uppercase text-black text-bolder ghd-ITCAvant">
                  Scopri le opzioni di assistenza a tua disposizione
                </span>
                <v-card
                  v-for="(item, index) in sendData.prodotti"
                  :key="index"
                  :class="`ghd-border-${item.warranty || item.hasOwnProperty('discount') ? 'black' : 'red'} mb-4`"
                >
                  <div
                    class="d-flex justify-space-between flex-wrap flex-md-nowrap flex-column flex-md-row"
                  >
                    <div class="align-self-start">
                      <v-img
                        width="100"
                        height="100%"
                        :src="item.url"
                        class="rounded"
                      ></v-img>
                    </div>
                    <div class="pt-2">
                      <span class="text-black ghd-ITCAvant text-center text-medium">{{ item.prodotto }}</span>
                      <v-card-text class="pl-0">
                        Seriale: {{ item.seriale }}
                      </v-card-text>
                      <div
                        v-if="item.discount"
                        class="text-black"
                      >
                        {{
                          `${dataTextNoWarrantyProductInDialog[getUserData().role.toUpperCase()].title} confermato`
                        }}
                      </div>
                      <v-card-actions
                        v-if="!item.warranty"
                        class="d-flex justify-space-between dense"
                      >
                        <div>
                          <v-btn
                            outlined
                            block
                            color="primary"
                            class="mb-4 mt-4"
                            @click="getScriptForNoWarranty(item, index)"
                          >
                            Continua
                          </v-btn>
                        </div>
                      </v-card-actions>
                    </div>
                    <div class="align-self-center mr-auto">
                    </div>
                  </div>
                </v-card>

                <dialog-info-no-garanzia
                  :visible="isDialogNoWarrantyVisible"
                  :data="responseDataNoGar"
                  @turnoff="isDialogNoWarrantyVisible = false"
                  @choosediscount="setDiscountToProduct($event)"
                />

                <v-btn
                  :color="checkSubmitForm() ? 'primary' : 'secondary'"
                  block
                  class="mb-4 mt-4"
                  @click="onSubmit()"
                >
                  Concludi
                </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>

    <dialog-resolved-problem
      v-if="isDialogNotConfirmDiscount"
      :data="dialogNoChooseDiscout"
      @turnoff="removeItem(currentProductForNoWarranty.value)"
    >
      <template v-slot:action>
        <router-link
          :to="{name: 'dashboard'}"
        >
          <v-btn
            color="primary"
            style="border: 2px solid white !important;"
          >
            Torna alla home
          </v-btn>
        </router-link>
      </template>
    </dialog-resolved-problem>
    <dialog-loader
      :visible="isDialogLoaderVisible"
    ></dialog-loader>
    <snackbar-response
      :visible.sync="isSnackbarResponseVisible"
      :data.sync="errorMessage"
      @close="isSnackbarResponseVisible = false"
    ></snackbar-response>
    <dialog-complete-ass
      :visible="isDialogResponseVisible"
      :data="responseData"
      @turnoff="closeAssistance()"
    />
    <dialog-where-is-data-code
      v-if="isDialogWhereisDataCodeVisible"
      @turnoff="isDialogWhereisDataCodeVisible = false"
    />
    <dialog-where-is-n-f-d-i
      v-if="isDialogWhereIsNFDI"
      @turnoff="isDialogWhereIsNFDI = false"
    />
    <dialog-whatsapp-number
      v-if="isDialogWhatsappVisible"
      @turnoff="isDialogWhatsappVisible = false"
    />
    <dialog-dynamic
      v-if="isDialogNotCanSendProductVisible"
      :data="dataWhereUserCannotSendProduct"
      @turnoff="isDialogNotCanSendProductVisible = false"
    >
      <template v-slot:action>
        <div style="flex: 1; margin: 5px">
          <div>
            <router-link
              :to="{name: 'dashboard'}"
            >
              <v-btn
                color="primary"
                block
                style="border: 2px solid white !important;"
              >
                Torna alla home
              </v-btn>
            </router-link>
          </div>
        </div>
        <div style="flex: 1; margin: 5px">
          <div>
            <v-btn
              color="primary"
              block
              style="border: 2px solid white !important;"
              @click="isDialogNotCanSendProductVisible = false"
            >
              Continua
            </v-btn>
          </div>
        </div>
      </template>
    </dialog-dynamic>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiArrowRightDropCircleOutline,
  mdiClose,
  mdiHelpCircle,
} from '@mdi/js'
import {
  computed, onMounted, ref, watch,
} from '@vue/composition-api'
import {
  required,
  requiredIf,
  alphaDashValidator,
  emailValidator,
  passwordValidator,
  confirmedValidator,
  alphaValidator,
  alphaWithSpacesValidator,
  integerValidator,
  lengthValidator,
  alphaSlashValidator,
  fileRequired,
  fileMaxSize,
  fileExtensionAccepted,
  dateOfPurchaseWarrantyValidity,
  serialNumberSyntaxValidator,
  checkDateIsSameOrBefore42Months,
  checkIfSerialNumberIsValidAndUnder42Months,
  checkIfDateOfPurchaseUnder42Months,
  checkGeneralValidity,
  checkIfSelectedFallDamage,

  // newRules
  checkDateIsOverExpMonths,
  checkIfSerialNumberIsPresent,
  checkIfSerialUnReadable,
  checkIfProofOfPurchaseAreSet,
  checkIfSerialNumberIsReadable,
  checkIfNoProofOPurchaseAreSet,
  checkIfSerialNumberIsOver42Month,
  checkIfDataCodeIsFake,
  checkIfDateOfPurchaseIsInWarranty,
  checkIfDateOfPurchaseIsBeetween42MonthAndExpMonths,
  checkDateIsOver42Months,
  rulesSyntaxSerialNumberValidator,
  checkIfSerialNumberOrProofOfPurchaseAreSet,

} from '@core/utils/validation'
import { getUserData, useRouter } from '@core/utils'
import axios from '@axios'
import Vue from 'vue'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import DialogInfoGaranzia from '@/views/dialogs/dialogInfoGaranzia.vue'
import assistanceService from '@/services/assistanceService'
import store from '@/store'
import DialogLoader from '@/views/dialogLoader.vue'
import SnackbarResponse from '@/views/snackbarResponse.vue'
import DialogCompleteAss from '@/views/dialogs/dialogCompleteAss.vue'
import ProductFaqs from '@/views/assistance/faqs/ProductFaqs.vue'
import DialogInfoNoGaranzia from '@/views/dialogs/dialogInfoNoGaranzia.vue'
import DialogWhereIsDataCode from '@/views/dialogs/dialogWhereIsDataCode.vue'
import DialogResolvedProblem from '@/views/dialogs/dialogResolvedProblem.vue'
import DialogWhereIsNFDI from '@/views/dialogs/dialogWhereIsNFDI.vue'
import DialogDynamic from '@/views/dialogs/dialogDynamic.vue'
import DialogWhatsappNumber from '@/views/dialogs/dialogWhatsappNumber.vue'

export default {
  components: {
    DialogWhatsappNumber,
    DialogWhereIsDataCode,
    DialogInfoNoGaranzia,
    ProductFaqs,
    SnackbarResponse,
    DialogLoader,
    DialogInfoGaranzia,
    DialogCompleteAss,
    DialogResolvedProblem,
    DialogWhereIsNFDI,
    DialogDynamic,
  },
  setup() {
    const {
      getSubHeaderText,
      fetchStepProducts,
      getStepProdcuts,
      getSearchedOrder,
      getStartAssistance,
      getActiveStep,
    } = assistanceService()

    onMounted(() => {
      if (getSearchedOrder() === 'empty') {
        store.commit('assistance/setStartAssistance', false)
        store.commit('assistance/setActiveStep', 1)
      }
      dayjs.extend(isSameOrAfter)
    })

    // dialog setup
    const isDialogWhatsappVisible = ref(false)
    const isDialogLoaderVisible = ref(false)
    const isDialogResponseVisible = ref(false)
    const isSnackbarResponseVisible = ref(false)
    const isDialogInfoGaranziaVisible = ref(false)
    const isDialogNoWarrantyVisible = ref(false)
    const isDialogWhereisDataCodeVisible = ref(false)
    const isDialogNotConfirmDiscount = ref(false)
    const isDialogWhereIsNFDI = ref(false)

    const errorMessage = ref([])
    const responseData = ref({})
    const responseDataNoGar = ref({})
    const currentProductForNoWarranty = ref(null)

    const products = ref({})
    const menu = ref(false)
    const menuref = ref(null)

    const productInSelection = ref({})

    // uso questa ref per fare in modo di tornare allo stato iniziale quando un prodotto viene trovato tramite codice seriale
    // (esempio: Trovato prodotto in gar, seleziona danno da caduta, poi cambia deve tornare a come era prima, ma prima non posso sapere se era gia fuori gar o meno)
    const startWarranty = ref(0)

    // Form Submitted Snackbar
    const isFormSubmittedSnackbarVisible = ref(false)

    // Template refs
    const step1Form = ref(null)
    const step2Form = ref(null)
    const step3Form = ref(null)
    const step4Form = ref(null)
    const step5Form = ref(null)
    const step6Form = ref(null)

    // Step Validation (for `rules` prop of `v-stepper-step`)
    // It will be updated when step is validated
    const stepValidationResult = ref({
      1: true,
      2: true,
      3: true,
      4: true,
      5: true,
      6: true,
    })

    const canSendProduct = ref(true)

    const vErrorMessage = ref('')

    const dataWhereUserCannotSendProduct = ref({})
    const isDialogNotCanSendProductVisible = ref(false)

    const sendData = ref({
      email: getUserData().email,
      role: getUserData().role.toUpperCase(),
      prodotti: [],
    })

    const searchedItem = ref({})

    const { router } = useRouter()

    const startAssistance = computed(() => getStartAssistance())

    const isPrivato = () => getUserData().role.toUpperCase() === 'PRIVATO'

    const expMonths = computed(() => {
      if (productInSelection.value.product.purchaseDate && dayjs(productInSelection.value.product.purchaseDate).isSameOrAfter(dayjs('2022-01-01')) && !productInSelection.value.product.autocompiled && isPrivato()) {
        return (parseInt(productInSelection.value.product.exp_months, 10) + 2)
      }

      return productInSelection.value.product.exp_months // prendo questi mesi perche me li passa gia con 2 mesi in piu
    })

    const isClientAtelier = () => getUserData().is_atelier

    const checkIfPurchaseDateForAtelierClient = date => dayjs(date).isSameOrAfter(dayjs('2020-01-01'))

    fetchStepProducts()

    const resetProductInSelection = (argument = null) => {
      if (argument === 'category') {
        productInSelection.value.category = null
      } else if (argument === 'product') {
        productInSelection.value.product = {
          itemPhotos: {},
          prodotto_id: null,
          codice_ean: null,
          nr_fattura: null,
          nr_ordine: null,
          purchaseDate: undefined,
          serialNumber: undefined,
        }
      } else {
        productInSelection.value = {
          product: {
            itemPhotos: {},
            prodotto_id: null,
            codice_ean: null,
            nr_fattura: null,
            nr_ordine: null,
            purchaseDate: undefined,
            serialNumber: undefined,
          },
          category: null,
        }
      }
    }

    resetProductInSelection()

    const checkIfUserIsSalone = () => getUserData().role.toUpperCase() === 'SALONE'

    const changeGarIfDamageIsFalldown = value => {
      // Questa chiave è presente solo nei prodotti autocompilati
      if (productInSelection.value.product.hasOwn('gar')) {
        productInSelection.value.product.gar = checkIfSelectedFallDamage(value) ? 0 : startWarranty
      }
    }

    const openWhatsAppDialog = () => {
      isDialogWhatsappVisible.value = !isDialogWhatsappVisible.value
    }

    const handlePrintMessage = product => {
      canSendProduct.value = true
      vErrorMessage.value = ''

      // data di acquisto ok
      if (product.purchaseDate && !product.noProofOfPurchase) {
        if (!checkDateIsOverExpMonths(product.purchaseDate, expMonths.value)) {
          if (checkIfSerialUnReadable(product.serialNumber)) {
            // ALERT1
            vErrorMessage.value = 'ATTENZIONE: La presente garanzia decade in caso di numeri di serie (date code) cancellati, danneggiati o modificati.'
          }
        } else if (checkDateIsOverExpMonths(product.purchaseDate, expMonths.value)) {
          // ALERT4
          vErrorMessage.value = 'ATTENZIONE: La garanzia per il tuo prodotto è scaduta.'
          if (checkIfSerialUnReadable(product.serialNumber) && !productInSelection.value.product.proofOfPurchase) {
            // ALERT5
            vErrorMessage.value = 'ATTENZIONE: Siamo spiacenti ma il tuo prodotto risulta essere sprovvisto di prova di acquisto e di codice seriale (date code) pertanto non può essere oggetto di assistenza'
            canSendProduct.value = false
            dataWhereUserCannotSendProduct.value = {
              title: 'ATTENZIONE',
              subtitle: 'Siamo spiacenti ma il tuo prodotto risulta essere sprovvisto di prova di acquisto e di codice seriale (date code) pertanto non può essere oggetto di assistenza',
            }
            isDialogNotCanSendProductVisible.value = true
          }

          if (checkIfSerialNumberIsOver42Month(product.serialNumber) && checkDateIsOver42Months(product.purchaseDate)) {
            // ALERT6
            vErrorMessage.value = 'ATTENZIONE: Siamo spiacenti ma il tuo prodotto è risultato fuori garanzia e non può essere oggetto di assistenza.'
            canSendProduct.value = false
          }
        }
      } else if (!product.autocompiled && (product.purchaseDate === undefined || !product.purchaseDate)
        && (product.noProofOfPurchase !== undefined && product.noProofOfPurchase === true)) {
        // ALERT 2
        vErrorMessage.value = 'ATTENZIONE: La garanzia sarà applicata esclusivamente in presenza di: SCONTRINO FISCALE, RICEVUTA FISCALE, FATTURA, CONFERMA ORDINE ONLINE (SITO GHD O RIVENDITORE AUTORIZZATO)'
        if (checkIfSerialUnReadable(product.serialNumber)) {
          // ALERT5
          vErrorMessage.value = 'ATTENZIONE: Siamo spiacenti ma il tuo prodotto risulta essere sprovvisto di prova di acquisto e di codice seriale (date code) pertanto non può essere oggetto di assistenza'
          canSendProduct.value = false
          dataWhereUserCannotSendProduct.value = {
            title: 'ATTENZIONE',
            subtitle: 'Siamo spiacenti ma il tuo prodotto risulta essere sprovvisto di prova di acquisto e di codice seriale (date code) pertanto non può essere oggetto di assistenza.',
          }
          isDialogNotCanSendProductVisible.value = true
        }
        if (checkIfSerialNumberIsOver42Month(product.serialNumber)) {
          // ALERT6
          vErrorMessage.value = 'ATTENZIONE: Siamo spiacenti ma il tuo prodotto è risultato fuori garanzia e non può essere oggetto di assistenza.'
          canSendProduct.value = false
          dataWhereUserCannotSendProduct.value = {
            title: 'ATTENZIONE',
            subtitle: 'Siamo spiacenti ma il tuo prodotto è risultato fuori garanzia e non può essere oggetto di assistenza.',
          }
          isDialogNotCanSendProductVisible.value = true
        }
      } else if (product.damage === 'D008') {
        vErrorMessage.value = 'Attenzione: il danno accidentale comporta il decadimento della garanzia.'
      }
    }

    watch(() => productInSelection.value.product, newValue => {
      handlePrintMessage(newValue)
    }, {
      deep: true,
    })

    watch(() => productInSelection.value.product.serialNumber, newValue => {
      if (newValue.length === 15 || newValue.length === 14) {
        store.dispatch('assistance/fetchOrder', { seriale: newValue })
          .then(response => {
            // eslint-disable-next-line no-use-before-define
            handlePopulateProductInSelection(response.data.data)
          })
      }
    })

    const setNoProofOfPurchase = () => {
      productInSelection.value.product.purchaseDate = null
    }

    const activeStep = computed(() => getActiveStep())

    searchedItem.value = getSearchedOrder()

    const changeActiveStep = step => {
      store.commit('assistance/setActiveStep', step)
    }

    const changeBreadCrumbs = breadcrumbs => {
      store.commit('global/setBreadCrumbs', breadcrumbs)
    }

    const changeHeaderText = param => {
      store.commit('assistance/setHeaderTextMessages', param.label)
    }

    const changeSubHeaderText = text => {
      store.commit('assistance/setSubHeaderTextMessages', text)
    }

    const handlePopulateProductInSelection = product => {
      productInSelection.value.product = {
        id: product.product.category.id,
        prodotto_id: product.product.id,
        codice_ean: product.CodiceEan,
        serialNumber: product.NumeroSeriale,
        invoiceNumber: product.NumeroFattura,
        purchaseDate: product.isOwnCodDocument ? product.DataDocumento : '',
        // eslint-disable-next-line no-nested-ternary
        purchaseLocation: product.isOwnCodDocument ? checkIfUserIsSalone() ? 'ghd Italia' : 'Sito ghd Italia' : '',
        exp_months: product.exp_months,
        label: product.product.category.label,
        url: product.product.category.url,
        defects: product.product.category.defects,
        product_description: product.product.DescArticolo,
        nr_fattura: product.NumDocumento,
        autocompiled: true,
        gar: isClientAtelier() && checkIfPurchaseDateForAtelierClient(product.DataDocumento) ? 1 : product.gar,
        isOwnCodDocument: product.isOwnCodDocument,
        proofOfPurchaseRequired: product.proofOfPurchaseRequired,
        CodDocumento: product.CodDocumento,
        itemPhotos: {},
      }

      startWarranty.value = product.gar
    }

    if (!Object.values(searchedItem.value).length) {
      router.push({ name: 'assistenza-searchitem' })
    } else if (searchedItem.value.id) {
      handlePopulateProductInSelection(searchedItem.value)

      changeActiveStep(4)
      store.commit('assistance/setStartAssistance', true)
      changeSubHeaderText('')
    }

    products.value = computed(() => getStepProdcuts())

    const checkIfGarMessageCanPrinted = () => !vErrorMessage.value && productInSelection.value.product.gar && productInSelection.value.product.CodDocumento !== 'BRI' && productInSelection.value.product.CodDocumento !== ''
    const checkIfNoGarMessageCanPrinted = () => vErrorMessage.value && !productInSelection.value.product.gar

    const save = d => {
      menuref.value.save(d)
    }

    const handleListOfItems = () => {
      const { category } = productInSelection.value

      return category ? products.value.value.find(data => data.id === category.id).childs.filter(item => item.active).map(item => ({
        ...item,
        // eslint-disable-next-line radix
        exp_months: (checkIfUserIsSalone() ? parseInt(item.exp_months) / 2 : item.exp_months),
      })) : null
    }

    const addItemIntoAssistance = () => {
      sendData.value.prodotti.push({
        ...{
          url: productInSelection.value.product.url,
          id: productInSelection.value.product.id,
          exp_months: expMonths.value,
          prodotto: productInSelection.value.product.label,
          seriale: productInSelection.value.product.serialNumber,
          foto_prodotto: productInSelection.value.product.itemPhotos,
          prova_acquisto: productInSelection.value.product.proofOfPurchase,
          data_acquisto: productInSelection.value.product.purchaseDate,
          numero_fattura: productInSelection.value.product.invoiceNumber,
          luogo_acquisto: productInSelection.value.product.purchaseLocation,
          guasto: productInSelection.value.product.damage,
          desc_guasto: productInSelection.value.product.descDamage,

          // Campi da utilizzare quando sarà integrato teseo
          prodotto_id: productInSelection.value.product.prodotto_id,
          codice_ean: productInSelection.value.product.codice_ean,
          nr_fattura: productInSelection.value.product.nr_fattura,
          nr_ordine: productInSelection.value.product.nr_ordine,
          product_description: productInSelection.value.product.product_description,

          // Campi per funzionalità frontend
          warranty: isClientAtelier() && checkIfPurchaseDateForAtelierClient(productInSelection.value.product.purchaseDate) ? 1 : checkGeneralValidity(productInSelection.value.product, expMonths.value),
          noProofOfPurchase: productInSelection.value.product.noProofOfPurchase,
        },
      })

      resetProductInSelection()
    }

    // Step Validation
    const handleStepValidation = (step, navigateNext = true, isFinalStep = false) => {
      // Get Form templateRef of Step from argument
      const templateRef = (() => {
        if (step === 1) return step1Form
        if (step === 2) return step2Form
        if (step === 3) return step3Form
        if (step === 4) return step4Form
        if (step === 5) return step5Form
        if (step === 6) return step6Form

        return null
      })()

      // If no validate step templateRef is found just return
      if (!templateRef) return

      // ho aggiunto questo controllo per permettere lo step back senza dover prima validare i campi inseriti
      const isStepValid = navigateNext && templateRef.value !== null ? templateRef.value.validate() : true

      // Assign to stepValidationResult
      stepValidationResult.value[step] = isStepValid

      if (isStepValid) {
        if (navigateNext && !isFinalStep) {
          if (step === 1) {
            changeActiveStep(2)
            changeBreadCrumbs(['Home', 'Assistenza', productInSelection.value.category.label])
            changeHeaderText(productInSelection.value.category)
            changeSubHeaderText('Seleziona e registra il tuo prodotto')
          } else if (step === 2) {
            if (productInSelection.value.product.faqs && productInSelection.value.product.faqs.length) {
              changeActiveStep(3)
              changeBreadCrumbs(['Home', 'Assistenza', productInSelection.value.product.label, 'Risoluzione problemi'])
              changeHeaderText({ label: `ASSISTENZA PER ${productInSelection.value.product.label}` })
              changeSubHeaderText('In questa sezione puoi ricevere consigli sulla risoluzione dei problemi.')
            } else {
              changeActiveStep(4)
              store.commit('assistance/setStartAssistance', true)
            }
          } else if (step === 3) {
            changeActiveStep(4)
            vErrorMessage.value = ''
            store.commit('assistance/setStartAssistance', true)
          } else if (step === 4) {
            addItemIntoAssistance()
            changeActiveStep(5)
          }
        } else if (!navigateNext) {
          if (step === 1) {
            resetProductInSelection()
            changeHeaderText({ label: 'INSERISCI IL TUO PRODOTTO E APRI LA PRATICA DI ASSISTENZA' })
            changeSubHeaderText('Seleziona la tipologia e registra il tuo prodotto')
          } else if (step === 2) {
            // se i dati sono auto compilati ritorno all'inizo dell'inserimento
            if (productInSelection.value.product.autocompiled) {
              changeActiveStep(1)
            }
            resetProductInSelection('product')
          }

          changeActiveStep(activeStep.value - 1)
        }

        // Show Snackbar
        if (isFinalStep) {
          changeActiveStep(1)

          // ? Some Ajax request which saves form data in database
          isFormSubmittedSnackbarVisible.value = true
        }
      }
    }

    const removeItem = index => {
      sendData.value.prodotti.splice(index, 1)
      if (!sendData.value.prodotti.length) {
        changeActiveStep(1)
      }
    }

    const handleFileUploadTexts = index => {
      if (index === 'F') {
        return 'Punta piastra/Fronte elettrico'
      }
      if (index === 'B') {
        return 'Lato destro'
      }
      if (index === 'R') {
        return 'Lato sinisto'
      }

      return 'Cavo'
    }

    const getLuoghiAcquisto = () => (checkIfUserIsSalone() ? [
      'Salone parrucchiere',
      'ghd Italia',
    ]
      : [
        'Salone parrucchiere',
        'Sito ghd Italia',
        'Sephora',
        'Rinascente',
        'Amazon',
        'Altri siti online',
      ])

    const labelForProofOfPurchase = () => {
      if (productInSelection.value.product.purchaseLocation === 'Amazon') {
        return 'Documentazione'
      }

      return checkIfUserIsSalone() ? 'Fattura diretta (FDI)' : 'Documenti'
    }

    const placeholderForProofOfPurchase = () => (checkIfUserIsSalone() ? 'Inserire fattura ghd' : 'Prova di acquisto')

    const assignCurrentProduct = product => {
      productInSelection.value.product = {
        ...product,
        ...productInSelection.value.product,
      }
      changeHeaderText(product)
      changeSubHeaderText('')
    }

    const dataTextCompleteAss = {
      NOGAR: {
        subtitle: 'Cercheremo di gestire la tua pratica di assistenza nel minor tempo possibile. Per agevolare il processo di controllo ti suggeriamo di serguire i passaggi sotto riportati',
        courierText: 'Contatta un corriere a tua scelta e spedisci il prodotto',
      },
      GAR: {
        subtitle: 'Abbiamo inviato all\'indirizzo e-mail inserito, il riepilogo della tua pratica di assistenza.\n Adesso prenota il ritiro, segui la procedura e attendi il corriere!',
        courierText: 'Prenota il ritiro gratuito',
      },
    }

    const dataTextNoWarrantyProductInDialog = {
      PRIVATO: {
        title: 'Preventivo di sostituzione',
      },
      SALONE: {
        title: 'Preventivo sconto rottamazione',
      },
    }

    const checkSubmitForm = () => sendData.value.prodotti.filter(item => item.warranty || item.hasOwnProperty('discount')).length

    const fileToBase64 = file => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        const base64 = reader.result.split(',')[1]
        resolve(base64)
      }
      reader.onerror = error => reject(error)
    })

    const base64ToFile = (base64Data, filename, mimeType) => {
      // convert base64 data to binary blob
      const byteString = atob(base64Data)
      const arrayBuffer = new ArrayBuffer(byteString.length)
      const uint8Array = new Uint8Array(arrayBuffer)
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i)
      }
      const blob = new Blob([arrayBuffer], { type: mimeType })

      // create new File instance
      return new File([blob], filename, { type: mimeType })
    }

    const setFileOnBase64 = file => {
      fileToBase64(file).then(response => {
        productInSelection.value.product.proofOfPurchase = base64ToFile(response, file.name, file.type)
      })
    }

    const setFileOnBase64ItemPhoto = (file, index) => {
      fileToBase64(file).then(response => {
        productInSelection.value.product.itemPhotos[index] = base64ToFile(response, file.name, file.type)
      })
    }

    const onSubmit = () => {
      if (!checkSubmitForm()) {
        Vue.$toast('Necessario scegliere conferma sconto/preventivo su tutti i prodotti.', {
          timeout: 2000,
        })

        return
      }

      isDialogLoaderVisible.value = true
      const formData = new FormData()

      formData.append('email', sendData.value.email)
      sendData.value.prodotti.forEach((item, index) => {
        formData.append(`prodotti[${index}][exp_months]`, item.exp_months)
        formData.append(`prodotti[${index}][prodotto]`, item.product_description ? item.product_description : item.prodotto)
        formData.append(`prodotti[${index}][seriale]`, item.seriale)
        formData.append(`prodotti[${index}][prova_acquisto]`, item.prova_acquisto)
        formData.append(`prodotti[${index}][data_acquisto]`, item.data_acquisto)
        formData.append(`prodotti[${index}][status_price]`, item.status_price)
        formData.append(`prodotti[${index}][numero_fattura]`, item.numero_fattura)
        formData.append(`prodotti[${index}][luogo_acquisto]`, item.luogo_acquisto)
        formData.append(`prodotti[${index}][guasto]`, item.guasto)
        formData.append(`prodotti[${index}][desc_guasto]`, item.desc_guasto)
        formData.append(`prodotti[${index}][item_icon]`, item.url)
        formData.append(`prodotti[${index}][prodotto_id]`, item.prodotto_id)
        formData.append(`prodotti[${index}][codice_ean]`, item.codice_ean)
        formData.append(`prodotti[${index}][nr_fattura]`, item.nr_fattura)
        formData.append(`prodotti[${index}][nr_ordine]`, item.nr_ordine)
        formData.append(`prodotti[${index}][foto_prodotto][B]`, item.foto_prodotto.B)
        formData.append(`prodotti[${index}][foto_prodotto][F]`, item.foto_prodotto.F)
        formData.append(`prodotti[${index}][foto_prodotto][L]`, item.foto_prodotto.L)
        formData.append(`prodotti[${index}][foto_prodotto][R]`, item.foto_prodotto.R)
        formData.append(`prodotti[${index}][warranty]`, item.warranty)
      })

      store.dispatch('assistance/storeAssistance', formData).then(response => {
        responseData.value = dataTextCompleteAss[response.data.data.stato]
        isDialogResponseVisible.value = true
      }).catch(() => {
        isSnackbarResponseVisible.value = true
      }).finally(() => {
        isDialogLoaderVisible.value = false
      })
    }

    const closeAssistance = () => {
      isDialogResponseVisible.value = false
      router.push({ name: 'dashboard' })
    }

    const handleRules = field => {
      if (field === 'proofOfPurchase') {
        if (productInSelection.value.product.noProofOfPurchase) {
          return [
            checkIfSerialNumberOrProofOfPurchaseAreSet(productInSelection.value.product.serialNumber, productInSelection.value.product.noProofOfPurchase),
          ]
        }

        if (productInSelection.value.product.proofOfPurchaseRequired || productInSelection.value.product.proofOfPurchaseRequired === undefined) {
          return [
            fileRequired,
            fileMaxSize,
            fileExtensionAccepted,
          ]
        }

        return [
          fileMaxSize,
          fileExtensionAccepted,
          checkIfSerialNumberOrProofOfPurchaseAreSet(productInSelection.value.product.serialNumber, productInSelection.value.product.noProofOfPurchase),
        ]
      }

      return []
    }

    const setSerialNumberReadable = () => {
      productInSelection.value.product.serialNumber = productInSelection.value.product.serialNumberReadable ? 'Non Leggibile' : ''
    }

    const checkIfAssistanceNeedConfirmation = () => {
      let needConfirmation = null
      sendData.value.prodotti.forEach(item => {
        if (!needConfirmation) {
          needConfirmation = !item.warranty
        }
      })

      return needConfirmation
    }

    const handleClosingStep = () => {
      if (checkIfAssistanceNeedConfirmation()) {
        changeHeaderText({ label: 'Completa la tua assistenza' })
        store.commit('assistance/setSubHeaderTextMessages', '')
        changeActiveStep(6)
      } else {
        onSubmit()
      }
    }

    // mi passo sia il prodotto che l'indice in cui é l'array cosi se poi lo devo rimuovere lo posso rimuovere (vedi funzione setDiscountToProduct)
    const getScriptForNoWarranty = (product, index) => axios.get('/api/product/get-reason-script',
      { params: { ...product, ...{ user_role: getUserData().role.toUpperCase() } } }).then(response => {
      // recupero il ruolo dall'utente registrato
      const { role } = getUserData()

      // recupero il titolo che deve essere stampato sul dialog della no garanzia
      const title = dataTextNoWarrantyProductInDialog[role.toUpperCase()]

      responseDataNoGar.value = { ...title, ...response.data.data, ...{ expMonths: response.data.data.exp_months } }

      currentProductForNoWarranty.value = index

      isDialogNoWarrantyVisible.value = true
    }).catch(error => {
      console.log(error)
    })
    const dialogNoChooseDiscout = ref({})

    const setDiscountToProduct = result => {
      if (!result.choose) {
        isDialogNoWarrantyVisible.value = false
        dialogNoChooseDiscout.value = {
          title: 'Preventivo non confermato',
          text: 'Ti ringraziamo per aver usato l\'assistenza online ghd',
        }
        isDialogNotConfirmDiscount.value = true
      } else {
        sendData.value.prodotti[currentProductForNoWarranty.value].discount = result
        sendData.value.prodotti[currentProductForNoWarranty.value].status_price = result.price
        isDialogNoWarrantyVisible.value = false
      }
    }

    return {
      menuref,
      step1Form,
      step2Form,
      step3Form,
      step4Form,
      menu,
      isDialogWhatsappVisible,
      isDialogLoaderVisible,
      isDialogResponseVisible,
      isSnackbarResponseVisible,
      isDialogInfoGaranziaVisible,
      isDialogWhereisDataCodeVisible,
      isDialogWhereIsNFDI,
      isDialogNotCanSendProductVisible,
      errorMessage,
      responseData,
      activeStep,
      products,
      sendData,
      productInSelection,
      searchedItem,

      responseDataNoGar,
      isDialogNoWarrantyVisible,
      currentProductForNoWarranty,
      startAssistance,
      dataTextNoWarrantyProductInDialog,
      vErrorMessage,

      isClientAtelier,
      setFileOnBase64,
      setFileOnBase64ItemPhoto,
      save,
      getLuoghiAcquisto,
      getStepProdcuts,
      getSubHeaderText,
      handleListOfItems,
      handleStepValidation,
      addItemIntoAssistance,
      removeItem,
      onSubmit,
      getUserData,
      handleRules,
      closeAssistance,
      getSearchedOrder,
      changeHeaderText,
      changeActiveStep,
      changeBreadCrumbs,
      getScriptForNoWarranty,
      handleClosingStep,
      changeSubHeaderText,
      setDiscountToProduct,
      assignCurrentProduct,
      handleFileUploadTexts,
      setSerialNumberReadable,
      labelForProofOfPurchase,
      checkSubmitForm,
      setNoProofOfPurchase,
      changeGarIfDamageIsFalldown,
      canSendProduct,
      placeholderForProofOfPurchase,
      checkIfUserIsSalone,
      isDialogNotConfirmDiscount,
      dialogNoChooseDiscout,
      dataWhereUserCannotSendProduct,

      startWarranty,
      checkIfGarMessageCanPrinted,
      checkIfNoGarMessageCanPrinted,
      checkIfAssistanceNeedConfirmation,
      expMonths,
      openWhatsAppDialog,

      newRules: {
        checkDateIsOverExpMonths,
        checkIfSerialNumberIsPresent,
        checkIfSerialUnReadable,
        checkIfProofOfPurchaseAreSet,
        checkDateIsOver42Months,
        checkIfSerialNumberIsReadable,
        checkIfNoProofOPurchaseAreSet,
        checkIfSerialNumberIsOver42Month,
        checkIfDataCodeIsFake,
        checkIfDateOfPurchaseIsInWarranty,
        checkIfDateOfPurchaseIsBeetween42MonthAndExpMonths,
      },

      // Field Validators
      validators: {
        required,
        requiredIf,
        alphaDashValidator,
        emailValidator,
        passwordValidator,
        confirmedValidator,
        alphaValidator,
        alphaWithSpacesValidator,
        integerValidator,
        lengthValidator,
        alphaSlashValidator,
        fileRequired,
        fileMaxSize,
        fileExtensionAccepted,
        checkDateIsSameOrBefore42Months,
        dateOfPurchaseWarrantyValidity,
        serialNumberSyntaxValidator,
        checkIfDataCodeIsFake,
        checkIfSerialNumberIsValidAndUnder42Months,
        checkIfDateOfPurchaseUnder42Months,
        rulesSyntaxSerialNumberValidator, // regola per validare solo la sintassi del seriale
      },

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiArrowRightDropCircleOutline,
        mdiClose,
        mdiHelpCircle,
      },
    }
  },
}
</script>
