<template>
  <v-dialog
    v-model="visible"
    persistent
    width="840"
  >
    <v-card class="ghd-base-color-black ">
      <div class="d-flex pa-6">
        <div>
          <img
            src="/images/logo-oro.png"
            width="70"
          >
        </div>
        <div
          class="ml-auto cursor-pointer"
          @click="$emit('turnoff')"
        >
          <svg
            version="1.1"
            x="0px"
            y="0px"
            width="20"
            height="20"
            viewBox="0 0 219.57 220.6"
            style="enable-background:new 0 0 219.57 220.6;"
            xml:space="preserve"
          >
            <path
              fill="white"
              d="M213.72,9.06L213.72,9.06c3.12,3.12,3.12,8.19,0,11.31L17.85,216.24c-3.12,3.12-8.19,3.12-11.31,0l0,0c-3.12-3.12-3.12-8.19,0-11.31L202.4,9.06C205.53,5.93,210.59,5.93,213.72,9.06z"
            />
            <path
              fill="white"
              d="M213.72,215.79L213.72,215.79c-3.12,3.12-8.19,3.12-11.31,0L6.54,19.92c-3.12-3.12-3.12-8.19,0-11.31l0,0c3.12-3.12,8.19-3.12,11.31,0l195.87,195.87C216.84,207.6,216.84,212.67,213.72,215.79z"
            />
          </svg>
        </div>
      </div>
      <v-card-title
        class="ghd-ITCAvant text-xlarge"
        style="color: white !important;"
      >
        {{ data.title.toUpperCase() }}
      </v-card-title>
      <v-card-text class="ghd-text-white">
        {{ data.text }}
      </v-card-text>
      <div class="d-flex justify-space-around mb-6 pb-8 text-center pr-6 pl-6">
        <div style="flex: 1; margin: 5px">
          <div>
            <slot name="action"></slot>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>

import { ref } from '@vue/composition-api'

export default {
  props: {
    data: {
      required: true,
      type: [Object, Array],
    },
  },
  setup() {
    const visible = ref(true)

    return {
      visible,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
