<template>
  <v-dialog
    v-model="isDialogVisible"
    width="900"
  >
    <template #activator="{ on, attrs }">
      <span
        class="ghd-link ghd-ITCAvant text-uppercase"
        v-bind="attrs"
        v-on="on"
      >
        Maggiori informazioni sulla garanzia
      </span>
    </template>

    <v-card>
      <span class="text-uppercase ghd-ITCAvant text-black text-medium ml-4 ">
        INFORMAZIONI SULLA GARANZIA
      </span>

      <v-card-text class="ghd-helveticanew">
        <p>
          La presente garanzia si applica esclusivamente ai prodotti ghd originali acquistati presso centri e/o rivenditori autorizzati ghd o sul sito ufficiale ghd e
          non copre prodotti riparati o per cui è stata richiesta assistenza da parte di tecnici o rappresentanti non appartenenti a ghd, o per cui sono state effettuate
          riparazioni con una o più parti non originali o non autorizzate da ghd.
        </p>
        <p>
          La presente garanzia decade in caso di numeri di serie o di identificazione cancellati, danneggiati o modificati.
        </p>
      </v-card-text>
      <span class="text-uppercase ghd-ITCAvant text-black text-medium ml-4">
        CI SONO DELLE ESCLUSIONI SULLA GARANZIA? <br />
      </span>
      <v-card-text class="ghd-helveticanew">
        Si, ci sono esclusioni sulla garanzia:
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title> (a) naturale usura;</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title> (b) danno intenzionale, conservazione anormale o condizioni di utilizzo, incidenti, negligenza da parte tua o di terzi;</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title> (c) se non si riesce a far funzionare o utilizzare i Prodotti in conformità con le istruzioni per l’utente;</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>(d) qualsiasi modifica o riparazione da parte tua o di terzi.</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>(e) la presente garanzia decade in caso di numeri di serie o di identificazione cancellati, danneggiati o modificati;</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>(f) non possiamo, inoltre, onorare la tua garanzia qualora il prodotto sia stato acquistato da un rivenditore non autorizzato.</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="isDialogVisible = false"
        >
          Ho capito
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'

export default {
  setup() {
    const isDialogVisible = ref(false)

    return { isDialogVisible }
  },
}
</script>

<style scoped>

</style>
