<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        lg="6"
        md="6"
      >
        <span class="titleFaqs text-ml text-black ghd-ITCAvant">
          SELEZIONA IL PROBLEMA RISCONTRATO
        </span>
        <ul class="customList">
          <li
            v-for="(faq, index) in product.faqs"
            :key="faq.question+product.id"
            :class="currentQuestion === faq ? 'text-grey questionSelected ghd-ITCAvant' : 'text-grey ghd-ITCAvant'"
            @click="changeCurrentQuestion(faq); changeCurrentAnswer(product.faqs[index].answers)"
          >
            {{ faq.question }}
          </li>
        </ul>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        md="6"
      >
        <div
          v-if="Object.keys(currentAnswer).length > 0"
        >
          <AnswerQuestion
            :key="randomKey"
            :answer.sync="currentAnswer"
          />
        </div>
        <div
          v-else
        >
          <v-img
            :src="product.url_real"
            class="rounded mt-0 mb-0 mr-auto ml-auto imgProduct"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import AnswerQuestion from '@/views/assistance/faqs/AnswerQuestion.vue'

export default {
  name: 'ProductFaqs',
  components: {
    AnswerQuestion,
  },
  props: {
    product: {
      type: [Array, Object],
      required: true,
    },
  },
  setup(props) {
    const randomKey = ref(Math.floor(Math.random() * 50))

    const currentQuestion = ref(null)

    const currentAnswer = ref({})

    watch(() => props.product, () => {
      currentAnswer.value = {} // mi serve che cosi quando cambia il prodotto resetto la risposta selezionata prima
    })
    const changeCurrentAnswer = val => {
      randomKey.value = Math.floor(Math.random() * 50)
      currentAnswer.value = val
    }

    const changeCurrentQuestion = val => {
      currentQuestion.value = val
    }

    return {
      randomKey,
      currentAnswer,
      currentQuestion,
      changeCurrentAnswer,
      changeCurrentQuestion,
    }
  },
}
</script>

<style lang="scss">
.customList {
  list-style-type: none;
  padding-left: 0 !important;
  margin-top: 1rem;
  /*line-height: 20px;*/
  li {
    padding: 0.5rem 0;
    text-transform: uppercase;
    font-size: 1.2rem;
    border-bottom : 1px solid grey;
    &:hover{
      background: #f1f1f1;
      cursor:pointer;
    }
  }
}
.titleFaqs{
  border-bottom : 1px solid grey;
  font-weight: normal;
  letter-spacing: 0.02rem;
}

.questionSelected{
  background: #f1f1f1;
}

.imgProduct{
 //width: 15rem;
}
</style>
