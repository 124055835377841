<template>
  <v-dialog
    v-model="visible"
    hide-overlay
    persistent
    width="300"
  >
    <v-card
      color="primary"
      dark
    >
      <v-card-text class="pt-3">
        Attendere
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { watch } from '@vue/composition-api'

export default {
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
  },
  setup(props) {
    watch(() => props.visible, newVal => {
      if (newVal) {
        document.body.style.setProperty('pointer-events', 'none')
      } else {
        document.body.style.removeProperty('pointer-events', 'none')
      }
    })

    return {
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
