<template>
  <div>
    <v-snackbar
      v-for="(message, index) in data"
      :key="index"
      v-model="visibleFromProp"
      rounded="pill"
      top
      :light="$vuetify.theme.dark"
      right
      color="#F44336"
      @input="$emit('close')"
    >
      {{ message }}
    </v-snackbar>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'

export default {
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
    data: {
      required: true,
      type: [Object, Array],
    },
  },
  setup(props) {
    const visibleFromProp = ref(false)
    watch(() => props.visible, newVal => {
      visibleFromProp.value = newVal
    })

    return {
      visibleFromProp,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
